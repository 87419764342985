import Api from './api';

export const getData = async () => { 
  let data = []
  await Api.get(`/integrationservice`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};


export const getList = async () => { 
  let data = []
  await Api.get(`/integrationservice/get-list`)
    .then(result => {
      data = result.data.data
    })
    .catch(err => {
      data = []
    })
  return data
};




export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/integrationservice`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

export const updateItem = async (formData) => { 
  let data = []
  await Api.put(`/integrationservice`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}



export const testAuthentication = async (formData) => { 
  let data = []
  await Api.post(`/integrationservice/test-authentication`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}


export const testService = async (formData) => { 
  let data = []
  await Api.post(`/integrationservice/test-service`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}



