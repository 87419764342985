import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import HeaderMenu from "../../components/header-menu"
import MyMaterialTable from '../../components/table'
import Api from "../../services/api"
import * as calledsActions from '../../redux/actions/calleds'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import 'jspdf-autotable'
import LogoMarca from '../../images/new-logo-cmtech.png'
import Divider from '@mui/material/Divider'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApiReports from "../../services/apireport"
import fileDownload from "js-file-download"
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today'
import Api2Talk from "src/services/api2talk"
import { PapiroConsole } from "src/utils/papiroConsole"
import * as FileService from '../../services/attachment.service';
import styled from 'styled-components';

import AttachmentIcon from '@mui/icons-material/Attachment';


const StyledTypography = styled(Typography)({

  cursor: 'pointer',
  '&:hover': {
    color: '#3f51b5',  // Cor ao passar o mouse
    textDecoration: 'underline',  // Sublinha o texto ao passar o mouse
  },
});


class ChatReport extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isAdm = false
    this.typingTimeout = null
    this.organizationId = null

    const { userSession } = this.props

    if (userSession == null || userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        this.isClient = false
      if (item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    if (userSession.user.organization)
      this.organizationId = userSession.user.organization.id

    this.state = {
      dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
      organizationId: this.organizationId,
      OrganizationFilter: 0,
      location: 0,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      showGrid: false,
      calledsList: [],
      organizationList: [],
      organizationAddressesList: [],
      calledPdfList: [],
      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: '',
      showHeader: false,
      strhtmlModalOpen: false,
      dialogHTMLString: [],
      radioSelectedOption: "1",

    }
  }

  openHTMLRenderModal = (item) => {

    this.setState({ loading: true })
    Api2Talk.get(`/chat/messages/bychat/${item.id}`)
      .then(result => {
        if (result.data.success) {
          PapiroConsole.log("result aqui")
          PapiroConsole.log(result)
          this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, dialogHTMLString: result.data.data, loading: false })
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        console.log("=== Catch(e) ===")
        console.log(err)
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })

  }

  closeHTMLRenderModal = () => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, dialogHTMLString: [] })
  }

  changeValues = async (stateName, value, text = '') => {

    this.setState({ [stateName]: value });


  };

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if (reload == "true")
      localStorage.setItem('reload', false)
    return null;
  }

  onChangePaginate = (e, page) => {
    this.setState({ page: page })
    this.getCalleds('', page)
  }

  exportPdf = _ => {
    this.getCalledsToPdf()
  }

  setPagination = (count, page) => {
    let totalPage = Math.ceil(count / 10)
    this.setState({ totalPages: totalPage })
    if (count > 0) {
      if (page == 1) {
        if (count < 10)
          this.setState({ pageStart: 1, pageEnd: count })
        else
          this.setState({ pageStart: 1, pageEnd: 10 })
      } else {
        let pageStart = ((page - 1) * 10) + 1
        if (count >= page * 10)
          this.setState({ pageStart: pageStart, pageEnd: page * 10 })
        else
          this.setState({ pageStart: pageStart, pageEnd: count })
      }
    } else
      this.setState({ totalPages: 0, page: 1, count: 0 })
  }


  async componentDidMount() {

  }





  getCalleds = async (filter = '', page = 1) => {
    const intl = this.props.intl;
    this.setState({ loading: true });

    let url = `/report/2talk-chat-report/paginate?page=${page}`

    url += `&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`

    this.setState({ showGrid: true })

    var writing = ""

    ApiReports.get(url)
      .then(result => {
        if (result.data.success) {

          console.log(result)
          console.log("result aqui")
          let count = result.data.count
          this.setState({ count: count })
          this.setPagination(count, page)

          let formattedList
          if (result.data.count > 0) {
            formattedList = result.data.data.map(item => {
              return {
                ...item,
                createdat: moment(item.createdat).format('DD/MM/YYYY HH:mm'),
                Message: <Tooltip title={intl.formatMessage({ id: "send.mail.report.view.email.body" })}>
                  <IconButton aria-label="textevidence"
                    onClick={e => this.openHTMLRenderModal(item)}>
                    <ChatBubbleOutlineIcon />
                  </IconButton>
                </Tooltip>
              }

            })
          }
          console.log("=== formattedList ===")
          console.log(formattedList)

          this.setState({ calledsList: formattedList, loading: false })
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        console.log("=== Catch(e) ===")
        console.log(err)
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
  }



  handleRadioChange = (event) => {
    if (event.target.value == "1") {
      this.setState({ radioSelectedOption: event.target.value, dateStart: moment().format('YYYY/MM/DD HH:mm:ss'), dateEnd: moment().format('YYYY/MM/DD HH:mm:ss') })
    }
    else if (event.target.value == "2") {
      this.setState({ radioSelectedOption: event.target.value, dateStart: moment().startOf('month').format('YYYY/MM/DD HH:mm:ss'), dateEnd: moment().format('YYYY/MM/DD HH:mm:ss') })
    }
    else if (event.target.value == "3") {
      this.setState({ radioSelectedOption: event.target.value, dateStart: moment().format('YYYY/MM/DD HH:mm:ss'), dateEnd: moment().format('YYYY/MM/DD HH:mm:ss') })
    }

  }

  getExcel = async () => {

    this.setState({ loading: true });
    var url = '/report/2talk-chat-report/excell';

    url += `?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`

     await ApiReports.get(url)
      .then((response) => {
        PapiroConsole.log(response)
        PapiroConsole.log("response")
        if (response.data.success) {
          PapiroConsole.log("success")
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: this.props.intl.formatMessage({ id: "report.sent" }) + " (" + this.props.userSession.user.email + ")",
          });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response.data.errors && response.data.errors[0] ? response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: error.data && error.data.response && error.data.response.data && error.data.response.data.errors && error.data.response.data.errors[0] ? error.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
      });

    const intl = this.props.intl;

    this.setState({ loading: false });
  }

  getMessageExcellBadUseFlag = (message, status) => {
    const intl = this.props.intl;
    let writing = ""
    if (status === intl.formatMessage({ id: "finished" })) {
      if (message === true)
        writing = this.props.intl.formatMessage({ id: "yes" })
      else if (message === false)
        writing = this.props.intl.formatMessage({ id: "no" })
    } else
      writing = this.props.intl.formatMessage({ id: "undefined" })

    return writing
  }




  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
    if (dateStart > dateEnd) {

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
        loading: false
      });

    } else {
      await this.getCalleds(this.props.location.state)
    }


  }

  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  renderDatePickerStart() {
    const { intl } = this.props
    return (
      <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
    );
  }
  renderDatePickerEnd() {
    const { intl } = this.props
    return (
      <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
    );
  }
  convertHtmlOption = value => {
    PapiroConsole.log("=== convertHtmlOption ===")
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let found = null
    for (let i in temp.children) {
      if (temp.children[i].innerHTML && temp.children[i].innerHTML.toString().trim() != '<br>' && temp.children[i].innerHTML.toString().trim().length > 0) {
        found += temp.children[i].innerHTML.toString()
      }
    }
    let sanitized = temp.textContent.trim() || temp.innerText.trim() || found

    PapiroConsole.log(sanitized)
    return sanitized
  }

  getFile = async (hirerid, fileName, filerepositorymethod) => {

    const intl = this.props.intl;
    this.setState({ loading: true })

    await FileService.getFileByPath(hirerid, fileName, filerepositorymethod)
      .then(result => {
        if (result.data.success) {
          if (result && result.data && result.data.data && result.data.success) {
            window.open(`${result.data.data}`, '_blank');
          }
          this.setState({ loading: false })
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        console.log("=== Catch(e) ===")
        console.log(err)
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
    //alert(JSON.stringify(url.data));


  };

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    const intl = this.props.intl


    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    console.log(this.state.dialogHTMLString)

    const tableList = () => {
      return (
        <>

          <Dialog scroll="paper" open={this.state.strhtmlModalOpen} onClose={this.closeHTMLRenderModal} aria-labelledby="form-dialog-title" fullWidth
            maxWidth="sm">

            <DialogTitle id="form-dialog-title" style={{ backgroundColor: '#3f50b5', color: 'white' }}>
              <span style={{ color: 'white', margin: '0px', padding: '0px' }}>{intl.formatMessage({ id: "messages.from.conversartion" })} - {this.state.dialogHTMLString != null ? this.state.dialogHTMLString.id : null}</span>
              <ClearIcon onClick={this.closeHTMLRenderModal} style={{ fill: "white", float: 'right', cursor: 'pointer' }} />
            </DialogTitle>
            <DialogContent style={{ padding: '25px' }} >
              <div dividers style={{ maxHeight: '400px', overflowY: 'auto', }}>
                {this.state.dialogHTMLString.length > 0 ?
                  <div>

                    {this.state.dialogHTMLString.map((item) => {
                      if (((item.messageorigin === 4) || (item.messageorigin === 1))) {

                        //return <div style={{marginBottom : '23px'}}> <p style={{fontSize:'15px'}}><span style={{textTransform : 'uppercase'}}>{item.senderuser.name}</span> :  {item.message}</p> </div>
                        return (
                          <Card className={classes.root} style={{ marginBottom: '15px' }}>
                            <CardContent style={{ width: '100%' }} >
                              <Typography style={{ width: "100%" }} align="right" variant={'body2'} className={classes.title} color="textSecondary" gutterBottom>
                                {item.sendername} - {moment(item.createdat).format('DD/MM/YYYY HH:mm')}
                              </Typography>

                              {item.attachment != null &&


                                <StyledTypography style={{ width: "100%" }} inline variant="body1" align="right" onClick={() => this.getFile(item.attachment.hirerid, item.attachment.path, item.attachment.filerepositorymethod)} >
                                  <IconButton size="small">
                                    <AttachmentIcon />
                                  </IconButton>
                                  {item.message != null ? this.convertHtmlOption(item.message) : null}
                                </StyledTypography>


                              }
                              {item.attachment == null &&
                                <Typography style={{ width: "100%" }} inline variant="body1" align="right" >
                                  {item.message != null ? this.convertHtmlOption(item.message) : null}
                                </Typography>
                              }



                            </CardContent>
                          </Card>
                        )
                      }
                      else {
                        return (
                          <Card className={classes.rootClient} style={{ marginBottom: '15px' }}>
                            <CardContent>
                              <Typography component={'span'} variant={'body2'} className={classes.title} color="textSecondary" gutterBottom>
                                {item.sendername} - {moment(item.createdat).format('DD/MM/YYYY HH:mm')}
                              </Typography>

                              {item.attachment != null &&


                                <StyledTypography onClick={() => this.getFile(item.attachment.hirerid, item.attachment.path, item.attachment.filerepositorymethod)} >
                                  <IconButton size="small">
                                    <AttachmentIcon />
                                  </IconButton>
                                  {item.message != null ? this.convertHtmlOption(item.message) : null}
                                </StyledTypography>

                              }
                              {item.attachment == null &&
                                <Typography >
                                  {item.message != null ? this.convertHtmlOption(item.message) : null}
                                </Typography>
                              }
                            </CardContent>
                          </Card>
                        );
                      }

                    })}
                  </div>
                  : <h4> {intl.formatMessage({ id: "chat-report.no.messages.between.users" })}</h4>
                }




              </div>

            </DialogContent>
            <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end', marginBottom:'10px' }}>
              <Grid item xs={12} sm={12} md={3} style={{  marginRight:'20px' }}> 
                <Button
                  onClick={this.closeHTMLRenderModal}
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="close" />}
                </Button>
              </Grid>
              </Grid>
          </Dialog>

          <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{intl.formatMessage({ id: "filters" })}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container style={{ marginBottom: 20 }}>
                  <Grid item xs={12} md={12} lg={4}>
                    <RadioGroup
                      row
                      value={this.state.radioSelectedOption}
                      onChange={this.handleRadioChange}
                    >
                      <FormControlLabel value="1" control={<Radio />} label={intl.formatMessage({ id: "today" })} />
                      <FormControlLabel value="2" control={<Radio />} label={intl.formatMessage({ id: "current.month" })} />
                      <FormControlLabel value="3" control={<Radio />} label={intl.formatMessage({ id: "period" })} />
                    </RadioGroup>
                  </Grid>
                  {this.state.radioSelectedOption == "3" &&
                    <>
                      <Grid item xs={12} md={6} lg={4}>
                        {this.renderDatePickerStart()}
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        {this.renderDatePickerEnd()}
                      </Grid>
                    </>
                  }

                </Grid>
              </AccordionDetails>
            </Accordion>


            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="filter" />}
                </Button>
              </Grid>
              {this.state.showGrid && <Grid item xs={6} sm={5} md={2} style={{ marginTop: 10, marginRight: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={this.getExcel}
                >
                  {<FormattedMessage id="export.plan" />}
                </Button>
              </Grid>}
            </Grid>
          </form>

          {
            this.state.showGrid && <>
              <MyMaterialTable
                title=""
                columns={
                  [
                    { title: intl.formatMessage({ id: "id" }), field: 'id' },
                    { title: intl.formatMessage({ id: "date" }), field: 'createdat' },
                    { title: intl.formatMessage({ id: "requestuser" }), field: 'requestinguser' },
                    { title: intl.formatMessage({ id: "assigned" }), field: 'assigneduser' },
                    { title: intl.formatMessage({ id: "channel" }), field: 'channelname' },
                    { title: intl.formatMessage({ id: "ProviderType" }), field: 'messageprovidername' },
                    { title: intl.formatMessage({ id: "Chats" }), field: 'Message' },
                  ]}
                data={this.state.calledsList} />

              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>
            </>
          }
        </>
      );
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
            <CssBaseline />


            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="requested.chats" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Can I="List" a="ChatReport">
              {tableList()}
            </Can>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

ChatReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatReport)))
ChatReport.contextType = AbilityContext